<template>
	<b-table
		:items="bookings"
		responsive
		:fields="tableColumns"
		primary-key="id"
		show-empty
		:empty-text="$t('No results found')"
		:sort-desc.sync="isSortDir"
		:sort-by.sync="sortBy"
		class="position-relative"
		sticky-header="40rem"
		no-border-collapse
  	>
		<template #cell(invoice)="row">
			<b-link
				@click="editBooking(row.item)"
				class="font-weight-bolder link-text"
			> {{ row.item.invoice }}
			</b-link>
		</template>

		<template #cell(fullname)="row">
			<!-- <b>{{$t('Housing')}}: </b>{{row.item.fullname}} <br> -->
			<b-link
				@click="editBooking(row.item)"
				class="link-text"
			> {{row.item.guestname}} <br>
			</b-link>
			<!-- <b>{{$t('Benefit')}}: </b>
				<span v-if="row.item.benefit.length">
					<b-badge class="mr-badge" variant="primary" v-for="(benefit, index) in row.item.benefit" :key="index">{{benefit.benefit__code}} </b-badge><br>
				</span>
				<span v-else> -- <br></span> -->
			<!-- <b>{{$t('Type of occupant')}}: </b>{{row.item.occupantname}}<br>
			<b>{{$t('Nights')}}: </b>{{row.item.nights}} -->
		</template>

		<template #cell(adults)="row">
			<div class="text-nowrap">
				{{$t('Adults')}}: <b >{{row.item.adults}} </b> <br>
				{{$t('Children')}}: <b >{{row.item.children}} </b>
			</div>
		</template>

		<template #cell(statusname)="row">
			<b-badge pill :variant="badgeStatus(row.item).variant">{{$t(badgeStatus(row.item).statusName)}} </b-badge>
		</template>

		<template #cell(actions)="row">
			<b-dropdown toggle-class="p-0" no-caret right>
				<template #button-content>
					<b-button variant="primary" size="sm">
						<feather-icon icon="MoreVerticalIcon" size="16" class="align-middle" style="color: white" />
					</b-button>
				</template>
				<b-dropdown-item @click="showCoupon(row.item)">
					<feather-icon icon="FileIcon" />
					<span class="align-middle ml-50"> {{$t('Show coupon')}}</span>
				</b-dropdown-item>
				<b-dropdown-item @click="editBooking(row.item)" v-if="canEditOrCancelBooking(row.item)">
				<!-- <b-dropdown-item @click="editBooking(row.item)"> -->
					<feather-icon icon="EditIcon" />
					<span class="align-middle ml-50"> {{$t('edit rsv')}}</span>
				</b-dropdown-item>
					<!-- <b-dropdown-item @click="requestCancelBooking(row.item)"> -->
				<b-dropdown-item @click="requestCancelBooking(row.item)" v-if="canEditOrCancelBooking(row.item)">
					<feather-icon icon="TrashIcon" />
					<span class="align-middle ml-50"> {{$t('cancel rsv')}}</span>
				</b-dropdown-item>
			</b-dropdown>

            <ModalCancelReservation :detail="row.item" :isLoading="isCancelingRsv" :cancelRsv="cancelRsv" :requiresTerms="requiresTermsAndConditions" @cancel-rsv-owner="cancelBooking" />
		</template>
	</b-table>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { showAlertMessage } from '@/helpers/helpers'
import { bookingBadgeStatus } from '@/data/data'

import ModalCancelReservation from '@/modules/owners/components/reservations/ModalCancelReservation'
import { utils } from "@/modules/owners/mixins/utils"
import * as moment from 'moment'

export default {
	mixins:[utils],
	components:{
		ModalCancelReservation
	},
	props:{
		bookings:{
			type: Array,
			required: true,
		},
		tableColumns:{
			type: Array,
			required: true,
		},
		sortBy:{
			type: String,
			required: true,
		},
		isSortDirDesc:{
			type: Boolean,
			required: true,
		},
		requiresTermsAndConditions:{
			type: Boolean,
			required: true,
		}
	},
	data() {
		return {
			attrLink: process.env.VUE_APP_IMG_SRC_API,
			isSortDir: this.isSortDirDesc,
			isSortBy: this.sortBy,
			optionsDateFormat: {
				weekday: "short",
				year: "numeric",
				month: "2-digit",
				day: "numeric"
			},
			cancelRsv: null,
			isCancelingRsv: false,
		}
	},
	computed:{
		...mapState('auth', ['userAdmin']),
	},
	methods:{
		...mapActions('owners', ['cancelOwnerBooking', 'fetchBookingsPenalities']),

		async showCoupon(booking){
			const order = `Booking Number: ${booking.invoice}` || ''
			const link = `${ this.attrLink }Transactions/ordersdetailcouponOwner/${booking.id}`
			window.open(link, '_blank').focus();
			await this.pushData('onClick', 'showCouponRsv', 'showCouponRsv', this.$route.name, order ) // push a back del registro
		},

		badgeStatus(booking){
			const { status } = booking
			return bookingBadgeStatus.find(state => state.status == status)
		},
		async requestCancelBooking(booking){
			const penalities = await this.fetchBookingsPenalities(booking.id)
			if (penalities) return this.$swal.fire({
				icon: "warning",
				title: `${this.$t("cannot cancell reservation")}`,
				text: `${this.$t("contact econcierge to cancell this booking")}`,
			})
			const dataRsv = {
				motiveCancel: null,
				cancelNote: '',
				penaltyNights: booking.nights || 0,
				extraCancelNote: '',
			}

			this.cancelRsv = dataRsv
			this.$root.$emit("bv::show::modal", "modal-cancelReserv" + booking.id)
			await this.pushData('onClick', 'openRsvCancellationRequest', 'openRsvCancellationRequest', this.$route.name ) // push a back del registro

		},
		async cancelBooking(data){
			this.isCancelingRsv = true
			const response = await this.cancelOwnerBooking(data)
			if (response) {
				this.isCancelingRsv = false
				this.$root.$emit('bv::hide::modal', 'modal-cancelReserv' + data.id)
				showAlertMessage( `${this.$t('book cancelled')}`, 'BellIcon', `${this.$t('the booking was cancelled')}`, 'success', 4000, 'bottom-right')
				this.cancelRsv = {
					motiveCancel: null,
					cancelNote: '',
					penaltyNights: 0,
					extraCancelNote: '',
				}
				await this.pushData('onClick', 'requestReservationCancellationHasBeenSent', 'requestReservationCancellationHasBeenSent', this.$route.name ) // push a back del registro
				this.$emit('get-bookings')
			} else this.isCancelingRsv = false
		},

		canEditBookingByStatus(booking){
			// solo se pueden modificar/cancelar los sig statuses
			const statusValid = [ 1, 2, 5] // Pendiente Confirmación, Confirmada, Pendiente Modificación
			const { status } = booking
			const numberStatus = parseInt(status)
			// si el status del booking está en el arreglo de status válidos
			return statusValid.includes(numberStatus)
		},
		canEditBookingByCheckin(booking){
			const dateCheckin = moment(booking.datein)
			const currentDate = moment().startOf('day')
			const daysDiff = dateCheckin.diff(currentDate, 'days')
			// los días de diferencia entre el checkin y la fecha actual sean mayores a cero
			return daysDiff > 0
		},
		canEditOrCancelBooking(booking){
			const { reservetype } = booking // 1-> principal, 2-> adicional
			const  canEditBookingByCheckin = this.canEditBookingByCheckin(booking)
			const  canEditBookingByStatus = this.canEditBookingByStatus(booking)

			// si el status del booking está en el arreglo de status válidos, La fecha para edición es válida
			return canEditBookingByStatus && canEditBookingByCheckin && reservetype !== '2'
		},

		async editBooking(booking){
            const previousRoute =  this.$route.name
			const order = `Booking Number: ${booking.invoice}` || ''
			this.$router.push({name: 'edit-booking', params: { id: booking.id } })
            await this.pushData('onClick', 'goToEditMyReservation', 'goToEditMyReservation', previousRoute, order) // push a back del registro
		},

	}
}
</script>

<style scoped>
thead {
    border-bottom: 1px solid #ccc;
  }
.link-text{
    text-decoration: underline solid 1.5px !important;
}
</style>